.homeParent {
  background-image: linear-gradient(to right, #8360c3, #2ebf91);

  height: 100vh;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.homeHeading h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  text-align: center;
}
.logo{
  margin-top: 10px;
    width: 33px;
    cursor: pointer;
}